.ant-menu-item-selected{
  background :#10161f !important;
}

.ant-menu-item{
  padding-left: 16px !important;
  height:45px !important;
}
.ant-menu-submenu-title{
  padding-left: 16px !important;
}
.profile-wrraper .ant-menu-submenu-arrow{
  margin-top: 8px !important;
}